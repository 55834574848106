(function ($) {
    var $installHelpIPhone = $('.installation-iphone');
    var $installHelpIPad = $('.installation-ipad');
    var $installHelpAndroid = $('.installation-android');
    var isIPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
    var isIPad = /iPad/.test(navigator.userAgent) && !window.MSStream;
    var isAndroid = /(android)/i.test(navigator.userAgent) && !window.MSStream;
    var isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone);
    var isAndroidStandAlone = window.matchMedia('(display-mode: standalone)').matches;
    var iOSVersion = getIOSVersion();

    function getIOSVersion() {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
            var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
            return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        }
    }

    function showInstallHelper($helper) {
        $helper.show();
    }

    if (isIPhone && !isInStandaloneMode && iOSVersion[0] >= 11) {
        showInstallHelper($installHelpIPhone);
    }

    if (isIPad && !isInStandaloneMode && iOSVersion[0] >= 11) {
        showInstallHelper($installHelpIPad);
    }

    if (isAndroid && !isAndroidStandAlone) {
        showInstallHelper($installHelpAndroid);
    }

    $('.installation').on('click', function() {
        $(this).hide();
    });
})(jQuery);